import React from 'react';

import Emoji from './Emoji';
import houseImg from '../../images/calculator/house.svg';
import incomeImg from '../../images/calculator/income.svg';
import subscriptionsImg from '../../images/calculator/subscriptions.svg';
import utilitiesImg from '../../images/calculator/utilities.svg';
import { amount } from '../../utils/general';
import Checkbox from './Checkbox';
import {
	CalcHeader,
	Wrapper,
	Body,
	SubSlider,
	UtilSlider,
	RentSlider,
	IncomeSlider,
	CalcButton,
	CalcRangeImg,
	CalcSlider,
	CalcHeadline,
	CalcLabel,
	CalcRange,
	CalcBody,
	ErrorText,
	CheckboxLabel,
	PeriodSlider,
	CalcTooltip,
} from '../../styles';

const CalcInputs = ({ inputs, handleChange, handleClick, handleCover, setPeriod, period, cover }) => {
	const { rent, utilities, subscriptions, income } = inputs;

	const covUtilities = cover.utilities ? utilities * period : utilities;
	const covRent = cover.rent ? rent * period : rent;
	const covSubscriptions = cover.subscriptions ? subscriptions * period : subscriptions;

	const positive = income * 0.76 - (covSubscriptions + covUtilities + covRent) > 0;

	return (
		<Wrapper alignCenter height="auto">
			<CalcHeader center>Financial Fitness Calculator</CalcHeader>
			<CalcHeadline center>Use the sliders to indicate rough income and bills per month</CalcHeadline>
			<CalcSlider>
				<CalcLabel>
					<CalcBody>Subscriptions</CalcBody>
					<CalcRangeImg src={subscriptionsImg} alt="subscriptions" className="subs" />
				</CalcLabel>
				<CalcRange>
					<SubSlider
						valueLabelDisplay="auto"
						min={0}
						max={500}
						step={20}
						value={subscriptions}
						onChange={(e, n) => handleChange('subscriptions', n)}
					/>
					<Body color="darkgrey">${amount(subscriptions)}</Body>
				</CalcRange>
			</CalcSlider>
			<CalcSlider>
				<CalcLabel>
					<CalcBody>Utilities</CalcBody>
					<CalcRangeImg src={utilitiesImg} alt="utilities" />
				</CalcLabel>
				<CalcRange>
					<UtilSlider
						valueLabelDisplay="auto"
						min={0}
						max={2000}
						step={50}
						value={utilities}
						onChange={(e, n) => handleChange('utilities', n)}
					/>
					<Body color="darkgrey">${amount(utilities)}</Body>
				</CalcRange>
			</CalcSlider>
			<CalcSlider>
				<CalcLabel>
					<CalcBody>Rent/mortgage</CalcBody>
					<CalcRangeImg src={houseImg} alt="house" />
				</CalcLabel>
				<CalcRange>
					<RentSlider
						valueLabelDisplay="auto"
						min={0}
						max={5000}
						step={100}
						value={rent}
						onChange={(e, n) => handleChange('rent', n)}
					/>
					<Body color="darkgrey">${amount(rent)}</Body>
				</CalcRange>
			</CalcSlider>
			<CalcSlider>
				<CalcLabel>
					<CalcBody>Income</CalcBody>
					<CalcRangeImg src={incomeImg} alt="income" />
				</CalcLabel>
				<CalcRange>
					<IncomeSlider
						valueLabelDisplay="auto"
						min={0}
						max={10000}
						step={100}
						value={income}
						onChange={(e, n) => handleChange('income', n)}
					/>
					<Body color="darkgrey">${amount(income)}</Body>
				</CalcRange>
			</CalcSlider>

			{/* Safety Blanket */}
			<CalcHeadline center className="safetyBlanket">
				Security blanket
				<CalcTooltip data-tooltip="guiding your spending if no further income over next 2-3 months">i</CalcTooltip>
			</CalcHeadline>

			{period > 1 && (
				<CalcSlider alignCenter className="coverLabel">
					<CalcLabel className="safetyBlanket coverLabel">
						<CalcBody>Cover my</CalcBody>
					</CalcLabel>
					<CalcRange className="safetyBlanket">
						<CheckboxLabel htmlFor="rentCheck">
							<Checkbox id="rentCheck" checked={cover.rent} onChange={() => handleCover('rent', !cover.rent)} />
							rent
						</CheckboxLabel>
						<CheckboxLabel htmlFor="utilitiesCheck">
							<Checkbox
								id="utilitiesCheck"
								checked={cover.utilities}
								onChange={() => handleCover('utilities', !cover.utilities)}
							/>
							utilities
						</CheckboxLabel>
						<CheckboxLabel htmlFor="subscriptionsCheck">
							<Checkbox
								id="subscriptionsCheck"
								checked={cover.subscriptions}
								onChange={
									() => handleCover('subscriptions', !cover.subscriptions)
									// eslint-disable-next-line react/jsx-curly-newline
								}
							/>
							subscriptions
						</CheckboxLabel>
					</CalcRange>
				</CalcSlider>
			)}

			<CalcSlider>
				<CalcLabel className="safetyBlanket">
					<CalcBody>for</CalcBody>
				</CalcLabel>
				<CalcRange className="safetyBlanket">
					<PeriodSlider
						valueLabelDisplay="auto"
						min={1}
						max={3}
						step={0.5}
						value={period}
						onChange={(e, n) => setPeriod(n)}
					/>
					<Body color="darkgrey" style={{ width: '85px' }}>
						{amount(period)} {period === 1 ? 'month' : 'months'}
					</Body>
				</CalcRange>
			</CalcSlider>

			{!positive ? (
				<ErrorText center>
					Your estimated outgoings seem to exceed your income, please adjust the amount{' '}
					<Emoji symbol="🙂" label="Slightly Smiling Face" />
				</ErrorText>
			) : null}
			<CalcButton small round onClick={handleClick} disabled={!positive}>
				Reveal Financial Fitness
			</CalcButton>
		</Wrapper>
	);
};

export default CalcInputs;
