import React from 'react';

import bubble1 from '../../images/home/autopilot/coins/bubble1.png';
import bubble2 from '../../images/home/autopilot/coins/bubble2.png';
import coins from '../../images/home/autopilot/coins/coin.png';
import { EnvCash, EnvBills, EnvVault, EnvEmergency } from './Envelope';
import { amount } from '../../utils/general';
import {
	LoadContainer,
	CoinImg,
	BubbleText,
	AnimEnvelopes,
	AnimEnvelope,
	LoadAnimWrapper,
	CalcHeader,
	CalcHeadline,
} from '../../styles';

const CalcEnv = ({ outputs: { vault, emergency, bills, cash } }) => {
	const view = 'viewCalc';

	return (
		<>
			<CalcHeader center>Financial Fitness Calculator</CalcHeader>
			<CalcHeadline center font="18px">
				dividing your money...
			</CalcHeadline>
			<LoadAnimWrapper>
				<LoadContainer>
					<CoinImg className={view} src={bubble1} alt="bubble1" />
					<CoinImg className={view} src={bubble2} alt="bubble2" />
					<CoinImg className={view} src={coins} alt="coins" />
					<BubbleText className={view}>${amount(vault + emergency + bills + cash)}</BubbleText>
					<BubbleText className={view}>${amount(vault + emergency + bills)}</BubbleText>
					<BubbleText className={view}>${amount(vault + emergency)}</BubbleText>
					<BubbleText className={view}>${amount(emergency)}</BubbleText>
					<BubbleText className={view}>$0</BubbleText>
					<AnimEnvelopes>
						<AnimEnvelope className={view}>
							<EnvCash view={view} initial={0} add={cash} />
						</AnimEnvelope>
						<AnimEnvelope className={view}>
							<EnvBills view={view} initial={0} add={bills} />
						</AnimEnvelope>
						<AnimEnvelope className={view}>
							<EnvVault view={view} initial={0} add={vault} />
						</AnimEnvelope>
						<AnimEnvelope className={view}>
							<EnvEmergency view={view} initial={0} add={emergency} />
						</AnimEnvelope>
					</AnimEnvelopes>
				</LoadContainer>
			</LoadAnimWrapper>
		</>
	);
};

export default CalcEnv;
