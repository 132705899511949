import React, { useState } from 'react';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../utils/firebase';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import CalcInputs from '../components/website/CalcInputs';
import CalcOutputs from '../components/website/CalcOutputs';
import CalcLoad from '../components/website/CalcLoad';
import CalcEnv from '../components/website/CalcEnv';

const Calculator = () => {
	const [inputs, setInputs] = useState({
		subscriptions: 50,
		utilities: 100,
		rent: 500,
		income: 3000,
	});
	const [period, setPeriod] = useState(1);
	const [cover, setCover] = useState({
		rent: false,
		utilities: false,
		subscriptions: false,
	});
	const [stage, setStage] = useState(1);
	const [outputs, setOutputs] = useState();

	const { subscriptions, utilities, rent, income } = inputs;

	const handleCover = (category, value) => {
		setCover(inputs => {
			return {
				...inputs,
				[category]: value,
			};
		});
	};

	const handleChange = (category, value) => {
		setInputs(inputs => {
			return {
				...inputs,
				[category]: value,
			};
		});
	};

	const handleClick = () => {
		setStage(2);
		const callable = httpsCallable(functions, 'calculator');
		return callable({ subscriptions, utilities, rent, income, cover, period })
			.then(res => {
				setOutputs(res.data);
				setTimeout(() => {
					setStage(3);
				}, 3000);
				setTimeout(() => {
					setStage(4);
				}, 11500);
			})
			.catch(() => {
				setStage(1);
			});
		// axios
		// 	.post('https://ks65gi0zm5.execute-api.us-east-2.amazonaws.com/test/envel-website-calculator', {
		// 		subscriptions,
		// 		utilities,
		// 		rent,
		// 		income,
		// 		cover,
		// 		period,
		// 	})
		// 	.then(res => {
		// 		setOutputs(JSON.parse(res.data.body));
		// 		setTimeout(() => {
		// 			setStage(3);
		// 		}, 3000);
		// 		setTimeout(() => {
		// 			setStage(4);
		// 		}, 11500);
		// 	})
		// 	.catch(() => {
		// 		setStage(1);
		// 	});
	};

	return (
		<Layout>
			<MetaData
				title="Envel - Financial Fitness Calculator"
				description="Use the Envel Financial Fitness Calculator to see how Envel Autopilot could help you increase your Net-Worth in just a year, as well as reveal your daily and weekend guilt-free cash spending limit."
				image="images/social/socialCalculator.png"
			/>

			{stage === 1 ? (
				<CalcInputs
					inputs={inputs}
					handleChange={handleChange}
					handleClick={handleClick}
					setPeriod={setPeriod}
					handleCover={handleCover}
					period={period}
					cover={cover}
				/>
			) : stage === 2 ? (
				<CalcLoad />
			) : stage === 3 ? (
				<CalcEnv outputs={outputs} />
			) : (
				<CalcOutputs outputs={outputs} setStage={setStage} inputs={inputs} period={period} />
			)}
		</Layout>
	);
};

export default Calculator;
