import React from 'react';

import cash from '../../images/home/envelopes/cash.png';
import emergency from '../../images/home/envelopes/emergency.png';
import bills from '../../images/home/envelopes/bills.png';
import vault from '../../images/home/envelopes/vault.png';
import { amount } from '../../utils/general';
import { EnvelopeBox, SmallText, Month, Img, Amount, EnvelopeName } from '../../styles';

export const EnvCash = ({ view, initial = 456, add = 1400 }) => (
		<EnvelopeBox color="#4b43f1">
			<EnvelopeName>Cash</EnvelopeName>
			<Img src={cash} alt="cash" />
			<Amount className={`${view} cash`}>+${amount(initial + add)}</Amount>
			<Amount className={`${view} cash`}>+${amount(initial)}</Amount>
			<Month className={`${view} cash`}>
				<SmallText right>this month +${amount(add)}</SmallText>
			</Month>
			<Month className={`${view} cash`}>
				<SmallText right>this month +$0</SmallText>
			</Month>
		</EnvelopeBox>
	);

export const EnvEmergency = ({ view, initial = 300, add = 800 }) => (
		<EnvelopeBox color="#ffa800">
			<EnvelopeName>Emergency</EnvelopeName>
			<Img src={emergency} alt="emergency" />
			<Amount className={`${view} emergency`}>+${amount(initial + add)}</Amount>
			<Amount className={`${view} emergency`}>+${amount(initial)}</Amount>
			<Month className={`${view} emergency`}>
				<SmallText right>this month +${amount(add)}</SmallText>
			</Month>
			<Month className={`${view} emergency`}>
				<SmallText right>this month +$0</SmallText>
			</Month>
		</EnvelopeBox>
	);

export const EnvBills = ({ view, initial = 500, add = 1000 }) => (
		<EnvelopeBox color="#1ac9e1">
			<EnvelopeName>Bills</EnvelopeName>
			<Img src={bills} alt="bills" />
			<Amount className={`${view} bills`}>+${amount(initial + add)}</Amount>
			<Amount className={`${view} bills`}>+${amount(initial)}</Amount>
			<Month className={`${view} bills`}>
				<SmallText right>this month +${amount(add)}</SmallText>
			</Month>
			<Month className={`${view} bills`}>
				<SmallText right>this month +$0</SmallText>
			</Month>
		</EnvelopeBox>
	);

export const EnvVault = ({ view, initial = 3000, add = 1200 }) => (
		<EnvelopeBox color="#e7383b">
			<EnvelopeName>Vault</EnvelopeName>
			<Img src={vault} alt="vault" />
			<Amount className={`${view} vault`}>+${amount(initial + add)}</Amount>
			<Amount className={`${view} vault`}>+${amount(initial)}</Amount>
			<Month className={`${view} vault`}>
				<SmallText right>this month +${amount(add)}</SmallText>
			</Month>
			<Month className={`${view} vault`}>
				<SmallText right>this month +$0</SmallText>
			</Month>
		</EnvelopeBox>
	);
