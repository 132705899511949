import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

import Phone from './Phone';
import Emoji from './Emoji';
import facebook from '../../images/footer/fb.svg';
import linkedIn from '../../images/footer/lk.svg';
import twitter from '../../images/footer/tw.svg';
import { amount } from '../../utils/general';
import { EnvCash, EnvBills, EnvVault, EnvEmergency } from './Envelope';
import {
	Body,
	Headline,
	EnvWrapper,
	EnvContainer,
	CalcHeader,
	CalcHeadline,
	CalcNet,
	Wrapper,
	SocialImg,
	CalcOutputsCont,
	SocialContainer,
	ShareContainer,
	CalcCircles,
	CalcCircle,
	DailyLimits,
	Limits,
	DailyNet,
	Subtext,
	ButtonText,
	NetText,
} from '../../styles';

const CalcOutputs = ({
	outputs: { vault, emergency, bills, cash, dailyLimit, weekendLimit, netWorth1, netWorth2 },
	inputs: { income, rent, utilities, subscriptions },
	setStage,
	period,
}) => {
	const view = 'view1 calc';
	const percentage = {
		income: (income / 10000) * 100,
		rent: (rent / income) * 100,
		utilities: (utilities / income) * 100,
		subscriptions: (subscriptions / income) * 100,
	};
	const url = 'www.envel.ai';
	const fbMessage = `With Envel's AI mobile banking app I can increase my net worth by $${amount(
		netWorth2
	)} in just one year. Find out how much you can save with the Envel Financial Fitness Calculator at www.envel.ai`;
	const twMessage = `With Envel's AI mobile banking app I can increase my net worth by $${amount(
		netWorth2
	)} in just one year. 🤑 Find out how much you can save with the Envel Financial Fitness Calculator at www.envel.ai ⚡`;

	return (
		<CalcOutputsCont>
			<CalcHeader center>Financial Fitness Calculator</CalcHeader>
			<CalcHeadline center font="18px">
				Envel Autopilot* could help you increase your Net-Worth by
			</CalcHeadline>
			<NetText row wrap alignCenter justifyCenter>
				<CalcNet center>
					${amount(netWorth1)} - ${amount(netWorth2)}
				</CalcNet>
				<CalcHeadline font="18px">
					in just a year <Emoji symbol="🥳" label="Partying Face" />
				</CalcHeadline>
			</NetText>
			<EnvWrapper row justifyBetween>
				<EnvContainer className={view}>
					<EnvCash initial={0} add={cash} />
				</EnvContainer>
				<EnvContainer className={view}>
					<EnvEmergency initial={0} add={emergency} />
				</EnvContainer>
				<EnvContainer className={view}>
					<EnvBills initial={0} add={bills} />
				</EnvContainer>
				<EnvContainer className={view}>
					<EnvVault initial={0} add={vault} />
				</EnvContainer>
			</EnvWrapper>
			<Headline center>
				We&apos;ve calculated a guilt free spending limit** to keep you on track for the next{' '}
				{period > 1 ? `${period} months` : 'month'}!
			</Headline>
			<DailyLimits row justifyCenter alignCenter>
				<CalcCircles>
					<CalcCircle>
						<CircularProgressbar
							value={percentage.income}
							strokeWidth={6}
							styles={buildStyles({
								trailColor: 'none',
								pathColor: '#4b43f1',
							})}
						/>
					</CalcCircle>
					<CalcCircle>
						<CircularProgressbar
							value={percentage.rent}
							strokeWidth={7}
							styles={buildStyles({
								trailColor: 'none',
								pathColor: '#1ac9e1',
							})}
						/>
					</CalcCircle>
					<CalcCircle>
						<CircularProgressbar
							value={percentage.subscriptions}
							strokeWidth={9}
							styles={buildStyles({
								trailColor: 'none',
								pathColor: '#e7383b',
							})}
						/>
					</CalcCircle>
					<CalcCircle>
						<CircularProgressbar
							value={percentage.utilities}
							strokeWidth={14}
							styles={buildStyles({
								trailColor: 'none',
								pathColor: '#ffa800',
							})}
						/>
					</CalcCircle>
				</CalcCircles>
				<Limits>
					<DailyNet center>Guilt-free Cash Limit</DailyNet>
					<DailyNet center>
						Daily limit: <strong>${amount(dailyLimit)}</strong>
					</DailyNet>
					<DailyNet center>
						Weekend limit: <strong>${amount(weekendLimit)}</strong>
					</DailyNet>
				</Limits>
			</DailyLimits>
			<Wrapper alignCenter>
				<ButtonText onClick={() => setStage(1)}>calculate again</ButtonText>
				<SocialContainer>
					<Phone />
					<ShareContainer row justifyBetween alignCenter>
						<Body color="darkgrey">share with friends</Body>
						<Wrapper row width="auto">
							<FacebookShareButton quote={fbMessage} url={url} hashtag="#EnvelRevolution">
								<SocialImg src={facebook} alt="facebook" />
							</FacebookShareButton>
							<LinkedinShareButton url={url}>
								<SocialImg src={linkedIn} alt="linkedIn" />
							</LinkedinShareButton>
							<TwitterShareButton title={twMessage} via={url} url={url} hashtags={['EnvelRevolution']}>
								<SocialImg src={twitter} alt="twitter" />
							</TwitterShareButton>
						</Wrapper>
					</ShareContainer>
				</SocialContainer>
				<Subtext>
					* estimated build up of savings through use of Envel Autopilot
					<br />
					** based on available cash minus funds apportioned for bills, emergency and vault
					<br />
					Note: As much as we care about you and your money, the financial fitness calculator is meant as a rough guide
					to budgeting for a pay period and doesn&apos;t account for information not available to Envel and is not
					intended to be used for purposes otherwise stated. The calculator should be used for informational and
					educational purposes only. Envel, nor nbkc bank, take responsibility for the use of this calculator.
				</Subtext>
			</Wrapper>
		</CalcOutputsCont>
	);
};

export default CalcOutputs;
