import React from 'react';

import brain from '../../images/home/autopilot/brain/brain.png';
import brainInside from '../../images/home/autopilot/brain/insideCircle.svg';
import brainOutside from '../../images/home/autopilot/brain/outsideCircle.svg';
import { LoadContainer, LoadImg, LoadAnimWrapper, CalcHeader, CalcHeadline } from '../../styles';

const CalcLoad = () => (
		<>
			<CalcHeader center>Financial Fitness Calculator</CalcHeader>
			<CalcHeadline center font="18px">
				calculating...
			</CalcHeadline>
			<LoadAnimWrapper>
				<LoadContainer>
					<LoadImg src={brain} alt="brain" />
					<LoadImg src={brainInside} alt="brainInside" />
					<LoadImg src={brainOutside} alt="brainOutside" />
				</LoadContainer>
			</LoadAnimWrapper>
		</>
	);

export default CalcLoad;
