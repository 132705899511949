import React from 'react';

import check from '../../images/calculator/check.svg';
import { CheckboxContainer, HiddenCheckbox, StyledCheckbox } from '../../styles';

const Checkbox = ({ className, checked, ...props }) => (
	<CheckboxContainer className={className}>
		<HiddenCheckbox checked={checked} {...props} />
		<StyledCheckbox checked={checked}>
			<img src={check} alt="checked" />
		</StyledCheckbox>
	</CheckboxContainer>
);

export default Checkbox;
